import React from "react";
import { Form, Select, Typography } from "antd";
import styles from "./formItems.module.scss";
import { EditOutlined } from "@ant-design/icons";
import clsx from "clsx";
const { Option } = Select;

function FormSelectInput({
  value,
  defaultValue,
  label,
  mode = "default",
  placeholder = "Choose",
  onChange,
  onDeselect,
  options = [],
  secondary,
  error,
  touched,
  disabled,
  showSearch = false,
  allowClear = false,
  size = "large",
  style,
  containerClassnames = "",
  containerProps = {},
  selectEleClassnames = "",
  dropdownRender,
  required = false,
  deletable = false,
  onDelete,
  editable = false,
  onEdit,
  suffixIcon,
  capitalizeLabel = true,
  selectProps = {},
  optionConfig = {
    label: "label",
    value: "value"
  }
}) {
  if (!Array.isArray(options)) {
    options = [];
  }

  let formSelectInputClassNames = styles.formSelectInputPrimary;
  if (secondary) {
    formSelectInputClassNames = styles.formSelectInputSecondary;
  }
  return (
    <Form.Item
      label={label}
      validateStatus={error && touched && "error"}
      help={error && touched ? error : ""}
      className={`${styles.formSelectInputContainer} ${containerClassnames} mb-0`}
      {...containerProps}
    >
      <Select
        value={value || undefined}
        allowClear={allowClear}
        showSearch={showSearch}
        placeholder={placeholder}
        defaultValue={defaultValue}
        mode={mode}
        style={style}
        onChange={onChange}
        onDeselect={onDeselect}
        className={`${formSelectInputClassNames} ${selectEleClassnames} rounded`}
        // filterOption={(input, option) =>
        //   option.props.children
        //     .toString()
        //     .toLowerCase()
        //     .indexOf(input.toLowerCase()) >= 0
        // }
        filterOption={(input, option) => option.props.label.toLowerCase().includes(input.toLowerCase())}
        dataSource={options}
        disabled={disabled}
        suffixIcon={suffixIcon}
        size={size}
        dropdownRender={dropdownRender}
        required={required}
        // optionFilterProp="children"
        optionLabelProp="label"
        autoFocus
        {...selectProps}
      >
        {options.map((i, key) => (
          <Option key={key} value={i[optionConfig.value]} {...i} label={i[optionConfig.label]}>
            <Typography.Text ellipsis className={clsx({ "text-capitalize": capitalizeLabel })}>
              {i.label}
              {
                <span>
                  {editable && <EditOutlined onClick={() => onEdit(i.value, i.label)} />} {deletable && <i className="fas fa-trash-alt" onClick={() => onDelete(i.value)} />}
                </span>
              }
            </Typography.Text>
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default FormSelectInput;
