import { get, put } from "utils/api";

/**
 * Call get all Contacts api
 * @returns {Promise<any>}
 */

export const updateIsBlockedByUser = async args => {
  const updateIsBlockedByUserResponse = await put(`/optout/${args.code}`, {
    number: args.number,
    sender: args.sender,
    global: args.global
  });
  return updateIsBlockedByUserResponse;
};

export const getOptOutInformation = async code => {
  const updateIsBlockedByUserResponse = await get(`/optout/${code}`);
  return updateIsBlockedByUserResponse;
};

//https://apit.gunisms.com.au/api/v1/useroptout/:code

export const UserUpdateIsBlockedByUser = async args => {
  const updateIsBlockedByUserResponse = await put(`/useroptout/${args.code}`, {
    number: args.number,
    sender: args.sender,
    global: args.global
  });
  return updateIsBlockedByUserResponse;
};

export const UserGetOptOutInformation = async code => {
  const updateIsBlockedByUserResponse = await get(`/useroptout/${code}`);
  return updateIsBlockedByUserResponse;
};

/**
 * Add new bulk api
 * @param bulkData
 * @returns {Promise<any>}
 */
