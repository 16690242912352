import MergeLabelsForm from "components/TargetGroup/GroupForms/MergeLabelsForm";
import SpinnerLoader from "components/AppLoader/SpinnerLoader";
import React, { useEffect, useState } from "react";
import AppButton from "components/AppButton/AppButton";
import { inject, observer } from "mobx-react";
import { Col, Row } from "antd";
import GroupForm from "./Forms/GroupForm";
import { isEmpty } from "lodash";
import { toJS } from "mobx";

const HubspotApp = ({ groupStore, integration, setStep, onSuccess, authStore }) => {
  const { hubspotFields, groupName, importContacts, handleConnect } = integration;
  const { setGroupData, group } = groupStore;
  const { user } = authStore;

  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [formStep, setFormStep] = useState(isEmpty(user?.hubSpotRefreshToken) ? 0 : 1);

  useEffect(() => {
    if (isEmpty(user?.hubSpotRefreshToken)) {
      handleConnect("hubspot", () => {
        setFormStep(1);
      });
    }
  }, []);

  useEffect(() => {
    if (formStep === 1)
      hubspotFields()
        .then(res => {
          if (res?.fields?.length > 0)
            setGroupData({
              headers: res?.fields.map((_, i) => ({
                id: `hub-${i}`,
                newLabel: _?.label,
                oldLabel: _?.name,
                type: _?.fieldType
              }))
            });
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          // setStep(1);
        });
  }, [formStep]);

  const onMergeLabels = (values, reset) => {
    values.name = groupName;
    delete values.headers;
    if (group) {
      values.name = group?.name;
      values.groupid = group?._id;
    }

    values.filter = filters.filter;
    if (filters?.filter === "lead") {
      values.filterGroups = [
        {
          filters: {
            propertyName: "hs_lead_status",
            operator: "IN",
            values: filters.leadStatus
          }
        }
      ];
    } else if (filters?.filter === "custom") {
      values.filterGroups = [filters.filterGroups[0]];
      if (values.filterGroups[0].filters[0].value === "") {
        delete values.filterGroups[0].filters[0].value;
      }
    } else if (filters.filter === "all") {
      values.filterGroups = null;
    } else if (filters.filter === "list") {
      values.filterGroups = [
        {
          filters: []
        }
      ];
      if (filters?.emailMarketing === "yes") {
        values.filterGroups[0].filters.push({
          operator: "NEQ",
          propertyName: "hs_email_optout_89662463",
          value: "true"
        });
      }
      if (filters?.emailOneToOne === "yes") {
        values.filterGroups[0].filters.push({
          operator: "NEQ",
          propertyName: "hs_email_optout_96682174",
          value: "true"
        });
      }
      values.listId = filters.listid;
    }

    importContacts("hubspot", values)
      .then(res => {
        if (res?.status) {
          onSuccess(res.data);
        }
      })
      .finally(() => {
        reset();
      });
  };

  const onBack = () => {
    setStep(1);
  };

  return (
    <Row type="flex" justify="center" className="h-100">
      {formStep === 0 && <SpinnerLoader />}
      {formStep === 1 ? (
        <Col span={24}>
          <GroupForm
            app={"hubspot"}
            onBack={onBack}
            setStep={() => {
              setFormStep(2);
            }}
            onSuccess={_ => {
              setFilters(_);
            }}
          />
        </Col>
      ) : (
        <Col span={24} className="my-3 h-100">
          {loading ? (
            <div className="text-center m-5 vh-center gap-3 fw-bold pt-5">
              <SpinnerLoader />
              Fetching Hubspot fields...
            </div>
          ) : (
            <MergeLabelsForm
              connectApp
              onSuccess={onMergeLabels}
              defaultLabel={[
                {
                  id: 3,
                  newLabel: "Email",
                  oldLabel: ""
                }
              ]}
              footerProps={
                <Col className="align-self-end">
                  <AppButton label={"Previous"} light withoutBg prefixIcon={<i className="bx bx-left-arrow-alt me-1" />} onClick={() => setFormStep(1)} />
                </Col>
              }
            />
          )}
        </Col>
      )}
    </Row>
  );
};

export default inject(stores => ({
  integration: stores.store.integrationStore,
  modalStore: stores.store.modalStore,
  groupStore: stores.store.groupStore,
  authStore: stores.store.authStore
}))(observer(HubspotApp));
