import React, { lazy } from "react";
import { Redirect, Link } from "react-router-dom";
import NavBar from "components/Navbar/Navbar";
import Checkout from "pages/Purchase/Checkout";
import Waiting from "pages/Other/Waiting";
// Others
const PageNotFound = lazy(() => import("../pages/Other/404/PageNotFound"));
const BuyCreditRequest = lazy(() => import("../pages/Other/BuyCreditRequest/BuyCreditRequest"));
const Onboarding = lazy(() => import("components/Authentication/Common/Onboarding"));
const Benefits = lazy(() => import("pages/Other/BenefitsPage/BenefitsPage"));

// ------------- new authentication components -------------
//login pages
const Login = lazy(() => import("pages/Authentication/SignIn/SignIn"));
const Password = lazy(() => import("pages/Authentication/SignIn/Password"));
const MFAuth = lazy(() => import("pages/Authentication/Verification/MfaVerify"));
const WelcomePage = lazy(() => import("pages/Authentication/Others/WelcomePage"));
const EmailMfaSelect = lazy(() => import("components/Authentication/Form/SignIn/EmailMfaSelection"));

//signup pages
const SignUp = lazy(() => import("pages/Authentication/SignUp/SignUp"));
const SignUpVerify = lazy(() => import("pages/Authentication/Verification/SignUpOtpVerify"));
const MobileSignUp = lazy(() => import("pages/Authentication/SignUp/MobileSignUp"));

// others
const ForgetPassword = lazy(() => import("pages/Authentication/SignIn/ForgetPassword"));
const SetNewPassword = lazy(() => import("pages/Authentication/Verification/SetNewPassword"));
const PasswordChanged = lazy(() => import("pages/Authentication/Others/PasswordChanged"));
const MobileOtpForm = lazy(() => import("pages/Authentication/Others/MobileOtpVerify"));
// ------------- new authentication components -------------

// Common Pages
const OptOutPage = lazy(() => import("pages/Optout/Optout"));
const UserOptOutPage = lazy(() => import("pages/Optout/UserOptout"));
const GatewayReply = lazy(() => import("modules/gateway-reply/GatewayReply"));
const ShortUrl = lazy(() => import("pages/Shorturl/Shorturl"));

// Authorized Pages
// super admin comps
const SharedNumber = lazy(() => import("modules/shared-number/SharedNumber"));
const DedicatedNoSettings = lazy(() => import("pages/SuperAdmin/SenderIdsPages/DedicatedNumber"));
const Users = lazy(() => import("pages/SuperAdmin/Dashboard/Users"));
const ParamList = lazy(() => import("modules/param/Params"));
const Monitor = lazy(() => import("modules/monitor/Monitor"));
const AdminDashboard = lazy(() => import("pages/SuperAdmin/Dashboard/Dashboard"));
const AdminUsage = lazy(() => import("pages/SuperAdmin/Dashboard/Usage"));
const DeviceInformation = lazy(() => import("pages/SuperAdmin/UsersPages/UserInformation"));
const CampaignApproval = lazy(() => import("pages/SuperAdmin/UsersPages/CampaignApproval"));
const BusinessName = lazy(() => import("pages/SuperAdmin/SenderIdsPages/BusinessName/Wrapper"));
const PersonalNumber = lazy(() => import("pages/SuperAdmin/SenderIdsPages/PersonalNumber"));
const DeletableUsersList = lazy(() => import("pages/SuperAdmin/UsersPages/DeleteUsers"));
const QuickActions = lazy(() => import("pages/Sms/Dashboard/QuickActions"));
const ProviderRoutes = lazy(() => import("pages/SuperAdmin/SenderIdsPages/ProviderRoutes"));

// old comps
// automation
const ContactForm = lazy(() => import("modules/automation/contact-form/ContactForm"));
const AddEditForm = lazy(() => import("modules/automation/contact-form/AddEditForm"));
// scheduler
const Scheduler = lazy(() => import("modules/scheduler/view/Scheduler"));
const fileScheduler = lazy(() => import("modules/scheduler/file-scheduler/FileScheduler"));
const FileSchedulerContainer = lazy(() => import("modules/scheduler/file-scheduler/FileSchedulerContainer"));

// new comps
// user
const Profile = lazy(() => import("pages/Profile/Profile"));
const SenderIds = lazy(() => import("pages/Profile/SenderIds"));
const Pricing = lazy(() => import("pages/Profile/Pricing"));
const Credits = lazy(() => import("pages/Profile/Credits"));
const Security = lazy(() => import("pages/Profile/Security"));
const Settings = lazy(() => import("pages/Profile/Settings"));
const Invoice = lazy(() => import("pages/Profile/Invoice"));
const EmailMfaList = lazy(() => import("pages/Profile/EmailOptList"));
// templates
const Templates = lazy(() => import("pages/Sms/Templates/Templates"));
const CreateTemplate = lazy(() => import("pages/Sms/Templates/CreateTemplate"));

// groups and contacts
const Audience = lazy(() => import("pages/Audience/Audience"));
const TargetGroupView = lazy(() => import("pages/Audience/TargetGroupView"));
const OptOutList = lazy(() => import("pages/Audience/OptOut"));
// purchase
const PurchaseGatewayCredit = lazy(() => import("pages/Purchase/PurchaseGateway"));

// new analytics dash
const DashboardNew = lazy(() => import("pages/Sms/Dashboard/Dashboard_new"));
const DashboardAdmin = lazy(() => import("pages/Sms/Dashboard/Dashboard_admin"));

// quick message
const QuickMessage = lazy(() => import("components/Navbar/QuickSMS"));
// sms/mms campaign
const Dashboard = lazy(() => import("pages/Sms/Dashboard/Dashboard"));
const SmsCampaign = lazy(() => import("pages/Sms/Campaign/SmsCampaign"));
const CampaignAnalytics = lazy(() => import("pages/Sms/Campaign/CampaignAnalytics"));
const Conversation = lazy(() => import("pages/Conversation/ConversationsContainer"));
const History = lazy(() => import("pages/Sms/History/History"));
const EmailSms = lazy(() => import("pages/Sms/EmailSms/EmailSms"));
const CampaignSteps = lazy(() => import("pages/Sms/Campaign/CampaignSteps"));

// automation
const Integration = lazy(() => import("pages/Integration/Integration"));
const MedicalContacts = lazy(() => import("pages/MedicalContacts/MedicalContacts"));
const ManageWorkflow = lazy(() => import("pages/Automation/WorkflowActions/Workflow"));
const ViewActionWorkflow = lazy(() => import("pages/Automation/WorkflowActions/ViewActionWorkflow"));

// role access (invitation)
const InviteUser = lazy(() => import("pages/RoleAccess/RoleAccess"));
const InvitationPage = lazy(() => import("pages/RoleAccess/InvitePage"));

// Mobile Authorized Pages
// superAdmin
const CampaignApprovalMobile = lazy(() => import("Mobile/Pages/Admin/CampaignApproval"));
const AdminDashboardMobile = lazy(() => import("Mobile/Pages/Admin/AdminDashboard"));
// comps
const MobileNavbar = lazy(() => import("Mobile/components/Navbar/MobileNavbar"));
const DashboardMobile = lazy(() => import("Mobile/Pages/Dashboard/Dashboard"));
const QuickSms = lazy(() => import("Mobile/Pages/QuickSms/QuickSms"));

const adminUserVerification = lazy(() => import("pages/SuperAdmin/UsersPages/UserVerification"));

// sms comps
const SmsCampaignMobile = lazy(() => import("Mobile/Pages/Campaign/SmsCampaign"));
const CampaignReportMobile = lazy(() => import("Mobile/Pages/Campaign/CampaignList"));
// const CampaignReportMobile = lazy(() =>
//   import("Mobile/Pages/Campaign/CampaignList")
// );
const SmsHistoryMobile = lazy(() => import("Mobile/Pages/History/History"));
// const SmsHistoryMobile = lazy(() => import("Mobile/Pages/History/History"));

// template comps
const TemplateListMobile = lazy(() => import("Mobile/Pages/Template/TemplateList"));
const CreateTemplateMobile = lazy(() => import("Mobile/Pages/Template/CreateTemplate"));

// group comps
const GroupList = lazy(() => import("Mobile/Pages/Group/GroupList"));
const ViewGroup = lazy(() => import("Mobile/Pages/Group/ViewGroup"));
const AddContact = lazy(() => import("Mobile/Pages/Group/AddContact"));

// purchase comps
const BuyCreditsMobile = lazy(() => import("Mobile/Pages/BuyCredits/BuyCredits"));
const InvoiceMobile = lazy(() => import("Mobile/Pages/BuyCredits/Invoice"));
const SendersIdMobile = lazy(() => import("Mobile/Pages/Dashboard/Senders"));
// dedicated numbers
const DedicatedNumbers = lazy(() => import("pages/DedicatedNumbers/NumberList"));

// verification page
const Verification = lazy(() => import("pages/Verification/Verification"));
const AdditionalDetails = lazy(() => import("pages/Verification/AdditionalDetails"));

// retail chat
const RetailChat = lazy(() => import("pages/RetailChat/RetailChat"));

const BatchMessage = lazy(() => import("pages/Sms/Batch/BatchMessages"));

const ConnectApp = lazy(() => import("pages/Other/ConnectApp"));

export const unauthorizedRoutes = [
  {
    path: "/",
    Component: Login,
    exact: true,
    title: "Sign-In"
  },
  {
    path: "/mfa-email-list",
    Component: EmailMfaSelect,
    exact: true,
    title: "Email Selection for Mfa"
  },
  {
    path: "/signup-welcome",
    Component: WelcomePage,
    exact: true,
    title: "Sign-Up Welcome"
  },
  {
    path: "/login",
    Component: Password,
    exact: true,
    title: "Password"
  },
  {
    path: "/verification",
    Component: MFAuth,
    exact: true,
    title: "Sign-In Verification"
  },
  {
    path: "/signup-verify",
    Component: SignUpVerify,
    exact: true,
    title: "Sign-Up Verification"
  },
  {
    path: "/signup-mobile",
    Component: MobileSignUp,
    exact: true,
    title: "Sign-Up Mobile"
  },
  {
    path: "/password-recovery",
    Component: ForgetPassword,
    exact: true,
    title: "Password Recovery"
  },
  {
    path: "/reset-password",
    Component: SetNewPassword,
    exact: true,
    title: "Reset Password"
  },
  {
    path: "/password-changed",
    Component: PasswordChanged,
    exact: true,
    title: "Password Changed"
  },
  {
    path: "/signup",
    Component: SignUp,
    exact: true,
    title: "Sign-Up"
  },
  {
    path: "/mobile-verify",
    Component: MobileOtpForm,
    exact: true,
    title: "Mobile Verify"
  },
  {
    path: "/reply/:code",
    Component: GatewayReply,
    exact: true,
    title: "Reply"
  },
  {
    path: "/optout/:code",
    Component: OptOutPage,
    exact: true,
    title: "Opt Out",
    stopPreview: true,
    stopTrackingScripts: true
  },
  {
    path: "/useroptout/:code",
    Component: UserOptOutPage,
    exact: true,
    title: "UserOptout",
    stopPreview: true,
    stopTrackingScripts: true
  },
  {
    path: "/shorturl/:code",
    Component: ShortUrl,
    exact: true,
    title: "Short URL"
  },
  {
    path: "/invitation/:code",
    Component: InvitationPage,
    exact: true,
    title: "Invitation"
  },
  {
    path: "/startup-benefits/stone-and-chalk",
    Component: Benefits,
    exact: true
  },
  {
    path: "*",
    Component: () => <Redirect to={"/"} />
  }
];

export const authorizedRoutes = [
  {
    path: "/auth/connect",
    Component: ConnectApp,
    removeSideBar: true,
    title: ""
  },
  {
    path: "/batch-message",
    Component: BatchMessage,
    exact: true,
    NavBar: () => {
      return <NavBar headerName={"Batch Message"} />;
    },
    title: "Batch Message"
  },
  // {
  //   path: "/retail-chat",
  //   Component: RetailChat,
  //   exact: true,
  //   NavBar: () => {
  //     return <NavBar headerName={"Retail Chat"} />;
  //   },
  //   title: "Retail Chat",
  //   retailChatSideBar: true
  // },
  {
    path: "/waiting/:redirect",
    Component: Waiting,
    exact: true,
    NavBar: () => {
      return <NavBar headerName={""} />;
    },
    title: "Waiting..."
  },
  {
    path: "/quick-actions",
    Component: QuickActions,
    exact: true,
    NavBar: () => {
      return <NavBar headerName={"Quick Actions"} />;
    },
    title: "Quick Actions"
  },
  {
    path: "/create-user-verification",
    Component: AdditionalDetails,
    exact: true,
    NavBar: () => {
      return <NavBar headerName={"Verification Form"} />;
    },
    title: "Create Verification Form"
  },
  {
    path: "/user-verification",
    Component: Verification,
    exact: true,
    NavBar: () => {
      return <NavBar headerName={"Verification"} />;
    },
    title: "Verification"
  },
  // Super Admin Routes
  {
    path: "/admin/shared-numbers",
    Component: SharedNumber,
    exact: true,
    isSuperAdmin: true,
    NavBar: () => {
      return <NavBar headerName="Shared Numbers" />;
    }
  },
  {
    path: "/admin/dedicated-numbers",
    Component: DedicatedNoSettings,
    exact: true,
    isSuperAdmin: true,
    NavBar: () => {
      return <NavBar headerName="Dedicated Numbers" />;
    }
  },
  {
    path: "/admin/user-templates/:id",
    Component: Templates,
    isSuperAdmin: true,
    NavBar: () => {
      return <NavBar headerName="User Templates" />;
    }
  },
  {
    path: "/admin/users",
    Component: Users,
    exact: true,
    NavBar: () => {
      return <NavBar headerName="Users" />;
    },
    isSuperAdmin: true
  },
  {
    path: "/admin/param",
    Component: ParamList,
    exact: true,
    isSuperAdmin: true
  },
  {
    path: "/admin/dashboard",
    Component: AdminDashboard,
    NavBar: () => {
      return <NavBar headerName="Admin Dashboard" />;
    },
    exact: true,
    isSuperAdmin: true
  },
  {
    path: "/admin/usage",
    Component: AdminUsage,
    exact: true,
    NavBar: () => {
      return <NavBar headerName="Admin System Usage" />;
    },
    isSuperAdmin: true
  },
  {
    path: "/admin/monitor",
    Component: Monitor,
    exact: true,
    isSuperAdmin: true
  },
  {
    path: "/admin/user-login-info/:id",
    Component: DeviceInformation,
    exact: true,
    isSuperAdmin: true,
    NavBar: () => {
      return <NavBar headerName="User Login Information" />;
    }
  },
  {
    path: "/admin/approval",
    Component: CampaignApproval,
    exact: true,
    isSuperAdmin: true,
    NavBar: () => {
      return <NavBar headerName="Approval List" />;
    }
  },
  {
    path: "/admin/business-names",
    Component: BusinessName,
    exact: true,
    isSuperAdmin: true,
    NavBar: () => {
      return <NavBar headerName="Business Names" />;
    }
  },
  {
    path: "/admin/personal-numbers",
    Component: PersonalNumber,
    exact: true,
    isSuperAdmin: true,
    NavBar: () => {
      return <NavBar headerName="Personal Numbers" />;
    }
  },
  {
    path: "/admin/deactive-users",
    Component: DeletableUsersList,
    exact: true,
    isSuperAdmin: true,
    NavBar: () => {
      return <NavBar headerName="Deactive Users" />;
    }
  },
  {
    path: "/admin/user-verification",
    Component: adminUserVerification,
    exact: true,
    isSuperAdmin: true,
    NavBar: () => {
      return <NavBar headerName="User Verification" />;
    }
  },
  {
    path: "/admin/provider-routes",
    Component: ProviderRoutes,
    exact: true,
    isSuperAdmin: true,
    NavBar: () => {
      return <NavBar headerName="SMS Routes" />;
    }
  },
  {
    path: "/admin/provider-routes/mms",
    Component: () => {
      return <ProviderRoutes type={"mms"} />;
    },
    exact: true,
    isSuperAdmin: true,
    NavBar: () => {
      return <NavBar headerName="MMS Routes" />;
    }
  },
  // New Comps Routes
  // templates
  {
    path: "/template",
    Component: Templates,
    NavBar: () => {
      return <NavBar headerName={"Template"} createBtn={true} />;
    },
    exact: true,
    title: "Template"
  },
  {
    path: "/template/add",
    Component: CreateTemplate,
    NavBar: () => {
      return (
        <NavBar
          headerName={
            <>
              <Link to="/template">Template</Link>
              <i className="fas fa-angle-right mx-2" />
              <small className="fw-bold fs-6">Create Template</small>
            </>
          }
        />
      );
    },
    exact: true,
    title: "Template"
  },
  {
    path: "/template/edit/:id",
    Component: CreateTemplate,
    NavBar: () => {
      return (
        <NavBar
          headerName={
            <>
              <Link to="/template">Templates</Link>
              <i className="fas fa-angle-right mx-2" />
              <small className="fw-bold fs-6">Edit Template</small>
            </>
          }
        />
      );
    }
  },
  {
    path: "/template/save/:id",
    Component: CreateTemplate,
    NavBar: () => {
      return (
        <NavBar
          headerName={
            <>
              <Link to="/template">Templates</Link>
              <i className="fas fa-angle-right mx-2" />
              <small className="fw-bold fs-6">Save Template As</small>
            </>
          }
        />
      );
    }
  },
  // groups
  {
    path: "/group",
    Component: Audience,
    NavBar: () => {
      return <NavBar headerName="Audience" />;
    },
    exact: true,
    title: "Group"
  },
  {
    path: "/group/:id",
    Component: TargetGroupView,
    NavBar: () => {
      return (
        <NavBar
          headerName={
            <>
              <Link to="/group">Audience</Link>
              <i className="fas fa-angle-right mx-2" />
              <Link to="/group" className="fw-bold fs-6">
                Groups
              </Link>
              <i className="fas fa-angle-right mx-2" />
              <small className="fw-bold fs-6">View Group</small>
            </>
          }
        />
      );
    },
    exact: true,
    title: "Group"
  },
  {
    path: "/opt-out",
    Component: OptOutList,
    NavBar: () => {
      return (
        <NavBar
          headerName={
            <>
              <Link to="/group">Audience</Link>
              <i className="fas fa-angle-right mx-2" />
              <small className="fw-bold fs-6">Opt-Out</small>
            </>
          }
        />
      );
    },
    exact: true,
    title: "Opt Out"
  },
  // user
  {
    path: "/profile",
    Component: Profile,
    NavBar: () => {
      return <NavBar headerName="Profile" />;
    },
    exact: true,
    title: "Profile"
  },
  {
    path: "/settings",
    Component: Settings,
    NavBar: () => {
      return <NavBar headerName="Settings" />;
    },
    exact: true,
    title: "Setting"
  },
  {
    path: "/sender-ids",
    NavBar: () => {
      return <NavBar headerName="Sender IDs" />;
    },
    Component: SenderIds,
    title: "Sender-Ids"
  },
  {
    path: "/invoice",
    NavBar: () => {
      return <NavBar headerName="Invoice" />;
    },
    Component: Invoice,
    title: "Invoice"
  },
  {
    path: "/pricing",
    NavBar: () => {
      return <NavBar headerName="Pricing" />;
    },
    Component: Pricing,
    title: "Pricing"
  },
  {
    path: "/credits",
    NavBar: () => {
      return <NavBar headerName="Credits" />;
    },
    Component: Credits,
    title: "Credits"
  },
  {
    path: "/security",
    NavBar: () => {
      return <NavBar headerName="Password Security" />;
    },
    Component: Security,
    title: "Security"
  },
  {
    path: "/email-mfa-list",
    NavBar: () => {
      return <NavBar headerName="2FA list" />;
    },
    Component: EmailMfaList,
    title: "2FA list"
  },
  {
    path: "/onboarding",
    Component: Onboarding,
    exact: true,
    title: "On Boarding"
  },
  // purchase routes
  {
    path: "/buy-credit-request",
    Component: BuyCreditRequest,
    NavBar: () => {
      return <NavBar headerName="Buy Credit Request" />;
    },
    exact: true,
    title: "Buy Credit Request"
  },
  {
    path: "/buy-credits",
    Component: PurchaseGatewayCredit,
    exact: true,
    title: "Buy Credit"
  },
  {
    path: "/buy-credits/checkout",
    Component: Checkout,
    exact: true,
    title: "Checkout"
  },
  // history
  {
    path: "/history",
    Component: History,
    NavBar: () => {
      return <NavBar headerName="History" />;
    },
    exact: true,
    title: "History"
  },
  // quick message
  {
    path: "/quick-message",
    Component: QuickMessage,
    NavBar: () => {
      return <NavBar headerName="Quick Message" />;
    },
    exact: true,
    title: "Quick Message"
  },
  // // mms
  // {
  //   path: "/mms/campaign/report/:id",
  //   Component: props => {
  //     return <CampaignAnalytics isMms={true} {...props} />;
  //   },
  //   NavBar: () => {
  //     return (
  //       <NavBar
  //         headerName={
  //           <>
  //             <Link to="/mms">MMS</Link>{" "}
  //             <i className="fas fa-angle-right mx-2" />
  //             <Link to="/mms/campaign" className="fw-bold fs-6">
  //               Campaign
  //             </Link>
  //             <i className="fas fa-angle-right mx-2" />
  //             <small className="fw-bold fs-6">Report</small>
  //           </>
  //         }
  //       />
  //     );
  //   },
  //   exact: true
  // },
  // dashboard
  {
    path: "/",
    // Component: Dashboard,
    Component: DashboardNew,
    NavBar: () => {
      return (
        <NavBar
          headerName={
            <>
              {/* <Link to="/">SMS</Link> <i className="fas fa-angle-right mx-2" /> */}
              <small className="fw-bold fs-6">Dashboard</small>
            </>
          }
        />
      );
    },
    exact: true,
    title: "Dashboard"
  },
  {
    path: "/dashboard-analytics",
    Component: DashboardNew,
    NavBar: () => {
      return <NavBar headerName={<small className="fw-bold fs-6">Dashboard</small>} />;
    },
    exact: true,
    title: "Dashboard Analytics"
  },
  {
    path: "/dashboard-analytics-admin",
    Component: DashboardAdmin,
    NavBar: () => {
      return <NavBar headerName={<small className="fw-bold fs-6">Dashboard</small>} />;
    },
    exact: true,
    title: "Dashboard Analytics"
  },
  // campaign
  {
    path: "/campaign/create",
    Component: CampaignSteps,
    NavBar: () => {
      return (
        <NavBar
          headerName={
            <>
              {/* <Link to="/">SMS</Link> <i className="fas fa-angle-right mx-2" /> */}
              <Link to="/campaign" className="fw-bold fs-6">
                Campaign
              </Link>
              <i className="fas fa-angle-right mx-2" />
              <small className="fw-bold fs-6">Create Campaign</small>
            </>
          }
        />
      );
    },
    exact: true,
    title: "Create Campaign"
  },
  {
    path: "/campaign",
    Component: SmsCampaign,
    NavBar: () => {
      return (
        <NavBar
          headerName={
            <>
              {/* <Link to="/">SMS</Link> <i className="fas fa-angle-right mx-2" /> */}
              <small className="fw-bold fs-6">Campaign</small>
            </>
          }
        />
      );
    },
    exact: true,
    title: "Campaign"
  },
  {
    path: "/campaign/report/:id",
    Component: CampaignAnalytics,
    NavBar: () => {
      return (
        <NavBar
          headerName={
            <>
              {/* <Link to="/">SMS</Link> <i className="fas fa-angle-right mx-2" /> */}
              <Link to="/campaign" className="fw-bold fs-6">
                Campaign
              </Link>
              <i className="fas fa-angle-right mx-2" />
              <small className="fw-bold fs-6">Report</small>
            </>
          }
        />
      );
    },
    exact: true,
    title: "SMS Campaign Report"
  },
  {
    path: "/conversation",
    Component: () => <RetailChat conversation={true} />,
    NavBar: () => {
      return <NavBar headerName={<small className="fw-bold fs-6">Conversation</small>} />;
    },
    exact: true,
    title: "Conversation"
  },
  // {
  //   path: "/inbox-messaging",
  //   Component: Conversation,
  //   NavBar: () => {
  //     return <NavBar headerName={<small className="fw-bold fs-6">Inbox Messaging</small>} />;
  //   },
  //   exact: true,
  //   title: "Inbox Messaging"
  // },
  {
    path: "/email-sms",
    Component: EmailSms,
    NavBar: () => {
      return (
        <NavBar
          headerName={
            <>
              {/* <Link to="/">SMS</Link> <i className="fas fa-angle-right mx-2" /> */}
              <small className="fw-bold fs-6">Email-SMS & SMS-Email</small>
            </>
          }
        />
      );
    },
    exact: true,
    title: "Email SMS"
  },
  // automation
  {
    path: "/integrations",
    Component: Integration,
    NavBar: () => {
      return <NavBar headerName="API Integrations" />;
    },
    exact: true,
    title: "Integrations"
  },
  {
    path: "/medical-contacts",
    Component: MedicalContacts,
    NavBar: () => {
      return <NavBar headerName="Medical Contacts" />;
    },
    exact: true,
    title: "Medical Contacts"
  },

  // dedicated number
  {
    path: "/dedicated-numbers",
    Component: DedicatedNumbers,
    NavBar: () => {
      return <NavBar headerName="Dedicated Numbers" />;
    },
    exact: true,
    title: "Dedicated Numbers"
  },

  // Old Comps Routes
  // scheduler
  {
    path: "/filescheduler",
    Component: fileScheduler,
    NavBar: () => {
      return <NavBar headerName="File Scheduler" />;
    },
    exact: true,
    title: "File Scheduler"
  },
  {
    path: "/scheduler",
    Component: Scheduler,
    NavBar: () => {
      return <NavBar headerName="Scheduler" />;
    },
    exact: true,
    title: "Scheduler"
  },
  {
    path: "/file-scheduler-form",
    Component: FileSchedulerContainer,
    NavBar: () => {
      return <NavBar headerName="File Scheduler" />;
    },
    exact: true,
    title: "File Scheduler Form"
  },
  // automation
  {
    path: "/automation/contact-form",
    Component: ContactForm,
    NavBar: NavBar,
    exact: true,
    title: "Contact Form"
  },
  {
    path: "/automation/contact-form/add-edit",
    Component: AddEditForm,
    NavBar: NavBar,
    exact: true,
    title: "Contact Form"
  },
  {
    path: "/automation/workflow",
    Component: ManageWorkflow,
    NavBar: () => {
      return (
        <NavBar
          headerName={
            <>
              <Link to="/">Automation</Link> <i className="fas fa-angle-right mx-2" />
              <small className="fw-bold fs-6">Workflow Actions</small>
            </>
          }
        />
      );
    },
    exact: true,
    title: "Automation"
  },
  {
    path: "/automation/workflow/:id",
    Component: ViewActionWorkflow,
    NavBar: () => {
      return (
        <NavBar
          headerName={
            <>
              <Link to="/">Automation</Link>
              <i className="fas fa-angle-right mx-2" />
              <Link to="/automation/workflow" className="fw-bold fs-6">
                Workflow Actions
              </Link>
              <i className="fas fa-angle-right mx-2" />
              <small className="fw-bold fs-6">View</small>
            </>
          }
        />
      );
    },
    exact: true,
    title: "Automation"
  },

  // role access
  {
    path: "/sub-accounts",
    Component: InviteUser,
    NavBar: () => {
      return <NavBar headerName="Sub-Accounts" />;
    },
    exact: true,
    title: "Role Access"
  },

  // Common and Redirect Routes
  {
    path: "/login",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/login*",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/password-recovery",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/mail-sent",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/reset-password*",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/password-changed",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/signup",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/new-account",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/email-verify",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/mobile",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/mobile-verify",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/email-exists",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/signup-verify",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/verification",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/signup-welcome",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/optout/:code",
    Component: OptOutPage,
    exact: true,
    title: "Optout",
    stopPreview: true,
    stopTrackingScripts: true
  },
  {
    path: "/useroptout/:code",
    Component: UserOptOutPage,
    exact: true,
    title: "UserOptout",
    stopPreview: true,
    stopTrackingScripts: true
  },
  {
    path: "/reply/:code",
    Component: GatewayReply,
    exact: true,
    title: "Reply"
  },
  {
    path: "/shorturl/:code",
    Component: ShortUrl,
    exact: true,
    title: "Short URL"
  },
  {
    path: "/invitation/:code",
    Component: InvitationPage,
    exact: true,
    title: "Invitation"
  },
  {
    path: "/startup-benefits/stone-and-chalk",
    Component: Benefits,
    exact: true
  },
  {
    path: "*",
    NavBar: NavBar,
    Component: PageNotFound
  }
];

export const authorizedMobileRoutes = [
  {
    path: "/waiting/:redirect",
    Component: () => <Waiting />,
    exact: true,
    NavBar: MobileNavbar,
    title: "Waiting..."
  },
  {
    path: "/auth/connect",
    Component: ConnectApp,
    removeSideBar: true,
    title: ""
  },
  {
    path: "/batch-message",
    Component: () => <BatchMessage />,
    exact: true,
    NavBar: MobileNavbar
  },
  // {
  //   exact: true,
  //   NavBar: MobileNavbar,
  //   path: "/inbox-messaging",
  //   title: "Inbox Messaging",
  //   Component: () => <Conversation isMobile={true} />
  // },
  {
    path: "/conversation",
    Component: () => <RetailChat conversation={true} isMobile={true} />,
    exact: true,
    NavBar: MobileNavbar
  },
  // {
  //   path: "/retail-chat",
  //   Component: () => <RetailChat isMobile={true} />,
  //   exact: true,
  //   NavBar: MobileNavbar
  // },
  {
    path: "/admin/dashboard",
    Component: AdminDashboardMobile,
    exact: true,
    NavBar: MobileNavbar
  },
  {
    path: "/admin/approval",
    Component: CampaignApprovalMobile,
    exact: true,
    NavBar: MobileNavbar
  },
  {
    path: "/admin/provider-routes",
    Component: ProviderRoutes,
    exact: true,
    NavBar: MobileNavbar
  },
  {
    path: "/admin/provider-routes/mms",
    Component: () => {
      return <ProviderRoutes type={"mms"} />;
    },
    exact: true,
    NavBar: MobileNavbar
  },
  {
    path: "/dashboard-analytics",
    Component: DashboardNew,
    NavBar: MobileNavbar,
    exact: true
  },
  // {
  //   path: "/quick-actions",
  //   Component: () => {
  //     return <TabsMenu index="0" body={<QuickActions isMobile={true} />} />;
  //   },
  //   NavBar: MobileNavbar,
  //   exact: true,
  //   title: "Quick Actions"
  // },
  {
    path: "/quick-actions",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/user-verification",
    Component: Verification,
    NavBar: MobileNavbar,
    exact: true,
    title: "Verification"
  },
  {
    path: "/create-user-verification",
    Component: AdditionalDetails,
    exact: true,
    NavBar: MobileNavbar,
    title: "Create Verification Form"
  },
  {
    path: "/dedicated-numbers",
    Component: DedicatedNumbers,
    NavBar: MobileNavbar,
    exact: true,
    title: "Dedicated Numbers"
  },
  {
    path: "/",
    Component: DashboardMobile,
    NavBar: MobileNavbar,
    exact: true,
    title: "Dashboard"
  },
  {
    path: "/onboarding",
    Component: Onboarding,
    NavBar: MobileNavbar,
    exact: true,
    title: "OnBoarding"
  },
  {
    path: "/home",
    Component: DashboardMobile,
    NavBar: MobileNavbar,
    exact: true,
    title: "Home"
  },
  {
    path: "/quick-message",
    Component: QuickSms,
    NavBar: MobileNavbar,
    exact: true,
    title: "Quick Message"
  },
  {
    path: "/invitation/:code",
    Component: InvitationPage,
    exact: true,
    title: "Invitation"
  },
  {
    path: "/campaign/create",
    Component: SmsCampaignMobile,
    NavBar: MobileNavbar,
    exact: true,
    title: "Create Campaign"
  },
  {
    path: "/campaign",
    Component: CampaignReportMobile,
    NavBar: MobileNavbar,
    exact: true,
    title: "Campaign"
  },
  {
    path: "/history",
    Component: SmsHistoryMobile,
    NavBar: MobileNavbar,
    exact: true,
    title: "History"
  },
  {
    path: "/template",
    Component: TemplateListMobile,
    NavBar: MobileNavbar,
    exact: true,
    title: "Template"
  },
  {
    path: "/template/add",
    Component: CreateTemplateMobile,
    NavBar: MobileNavbar,
    exact: true,
    title: "Template"
  },
  {
    path: "/template/edit/:id",
    Component: CreateTemplateMobile,
    NavBar: MobileNavbar,
    exact: true,
    title: "Template"
  },
  {
    path: "/template/save/:id",
    Component: CreateTemplateMobile,
    NavBar: MobileNavbar,
    exact: true,
    title: "Template"
  },
  {
    path: "/group",
    Component: GroupList,
    NavBar: MobileNavbar,
    exact: true,
    title: "Group"
  },
  {
    path: "/group/:id",
    Component: ViewGroup,
    NavBar: MobileNavbar,
    exact: true,
    title: "Group"
  },
  {
    path: "/group/add/:id",
    Component: AddContact,
    NavBar: MobileNavbar,
    exact: true,
    title: "Group"
  },
  {
    path: "/group/edit/:id",
    Component: AddContact,
    NavBar: MobileNavbar,
    exact: true,
    title: "Group"
  },
  {
    path: "/buy-credits",
    Component: BuyCreditsMobile,
    NavBar: MobileNavbar,
    exact: true,
    title: "Buy Credit"
  },
  {
    path: "/buy-credits/checkout",
    Component: Checkout,
    NavBar: MobileNavbar,
    exact: true,
    title: "Checkout"
  },
  {
    path: "/buy-credit-request",
    Component: BuyCreditRequest,
    NavBar: MobileNavbar,
    exact: true,
    title: "Buy Credit Request"
  },
  {
    path: "/invoice",
    Component: InvoiceMobile,
    NavBar: MobileNavbar,
    exact: true,
    title: "Invoice"
  },
  {
    path: "/sender-ids",
    Component: SendersIdMobile,
    NavBar: MobileNavbar,
    exact: true,
    title: "Sender Ids"
  },
  {
    path: "/shorturl/:code",
    Component: ShortUrl,
    exact: true,
    title: "Short URL"
  },
  {
    path: "/optout/:code",
    Component: OptOutPage,
    exact: true,
    title: "Optout",
    stopPreview: true,
    stopTrackingScripts: true
  },
  {
    path: "/useroptout/:code",
    Component: UserOptOutPage,
    exact: true,
    title: "UserOptout",
    stopPreview: true,
    stopTrackingScripts: true
  },
  {
    path: "/reply/:code",
    Component: GatewayReply,
    exact: true,
    title: "Reply"
  },
  {
    path: "/login",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/login*",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/password-recovery",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/mail-sent",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/reset-password*",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/password-changed",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/signup",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/new-account",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/email-verify",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/mobile",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/mobile-verify",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/email-exists",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/signup-verify",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/verification",
    Component: () => <Redirect to={"/"} />
  },
  {
    path: "/startup-benefits/stone-and-chalk",
    Component: Benefits,
    exact: true
  },
  {
    path: "*",
    NavBar: MobileNavbar,
    Component: PageNotFound
  }
];

export const unauthorizedMobileRoutes = [];
export const fullRoutes = [...unauthorizedRoutes, authorizedRoutes];
