import React, { Fragment, useEffect, useRef, useState } from "react";
import { Modal, Row, Col, Tabs } from "antd";
import styles from "assets/css/modals.module.css";
import { inject, observer } from "mobx-react";
import { Form, Formik } from "formik";
import HubspotApp from "components/ConnectApp/HubspotApp";
import ZohoApp from "components/ConnectApp/ZohoApp";
import FileUpload from "assets/img/fileUpload.png";
import FileDownload from "assets/img/fileDownload.png";
import HubspotLogo from "assets/images/thirdparty app/hubspot.png";
import ActiveCampLogo from "assets/images/thirdparty app/activeCampaignLogo.png";
import ZohoLogo from "assets/images/thirdparty app/zoho.png";
import GroupForm from "components/ConnectApp/Forms/GroupForm";
import AlertModal from "./AlertModal";
import { useHistory } from "react-router-dom";
import ActiveCampaignApp from "components/ConnectApp/ActiveCampaignApp";
import BrandProgressiveBar from "components/AppLoader/BrandProgressiveBar";

const ImportContactsModal = ({ sync = () => {}, modalStore, integration, groupStore, authStore }) => {
  const { handleConnect, setProps } = integration;
  const { setGroupData, group } = groupStore;
  const { user } = authStore;

  const formikRef = useRef();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [method, setMethod] = useState("file");
  const [groupId, setGroupId] = useState();
  const [successAlert, setSuccessAlert] = useState(false);
  const [hubspotFilter, setHubspotFilter] = useState({});

  useEffect(() => {
    setStep(1);
    setGroupData();
    // setProps({
    //   btn: () => {
    //     modalStore.toggleModal("showImportContactsModal", false);
    //     modalStore.toggleModal("importContactsModal", true);
    //   }
    // })
  }, []);

  const onFileImport = () => {
    setMethod("file");
    modalStore.toggleModal("importContactsModal", false);
    modalStore.toggleModal("showImportContactsModal", true);
  };

  const onSuccess = response => {
    setGroupId(response?.group?._id);
    setSuccessAlert(true);
    setStep(1);
    modalStore.toggleModal("importContactsModal", false);
  };

  const onBack = () => {
    setStep(1);
  };

  return (
    <Fragment>
      <Modal
        title={
          <div className="text-center">
            <h4 className="pt-3 fw-bold">Sync Contacts</h4>
            <p className={`mb-0 font-size-14 text-muted`}>Now choose Sync contacts from various integrated apps.</p>
          </div>
        }
        closeIcon={
          <div className={`btn ${styles.modalCloseBtn}`}>
            <i className="fas fa-times"></i>
          </div>
        }
        visible={modalStore.importContactsModal}
        onCancel={() => modalStore.toggleModal("importContactsModal", false)}
        width={650}
        footer={null}
        centered
        maskStyle={{
          background: "linear-gradient(135deg, #00000050, #107c9850)"
        }}
      >
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane style={{ minHeight: "355px" }} tab="Import Contacts" key="1" className="vh-center">
            <div className="import-icon p-3" onClick={onFileImport} style={{ width: "220px", height: " 285px" }}>
              <img src={FileUpload} alt="file upload" />
              <div>
                <p>
                  Import file from <br /> device
                </p>
                <small className="text-muted">Upload any CSV, XLS, or XLSX files with contact, company, deal, ticket, and product information.</small>
              </div>
            </div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              download
              title="sample_bulk_contacts.xlsx"
              href={process.env.PUBLIC_URL + "/images/sample_bulk_contacts.xlsx"}
              className="import-icon p-3"
              style={{ width: "220px", height: " 285px" }}
            >
              <img src={FileDownload} alt="file download" />
              <div>
                <p>
                  Download template <br /> file
                </p>
                <small className="text-muted">You can download this template file to quickly handle the excel.</small>
              </div>
            </a>
          </Tabs.TabPane>
          <Tabs.TabPane style={{ minHeight: "355px" }} tab="Sync Contacts" key="2">
            <div className="p-1 h-100">
              {step === 1 ? (
                <Formik
                  enableReinitialize
                  innerRef={formikRef}
                  initialValues={{ app: "hubspot" }}
                  onSubmit={({ app }, { setSubmitting }) => {
                    new Promise(resolve => {
                      setMethod(app);
                      switch (app) {
                        case "hubspot":
                          // if (!isEmpty(user?.hubSpotRefreshToken)) {
                          //   setStep(isEmpty(group) ? 2 : 3);
                          //   resolve();
                          // } else {
                          // handleConnect(app, () => {
                          setStep(3);
                          resolve();
                          // });
                          // }
                          break;
                        case "zoho":
                          setStep(4);
                          resolve();
                          break;
                        case "ac":
                          setStep(5);
                          resolve();
                          break;
                      }
                    }).finally(() => setSubmitting(false));
                  }}
                >
                  {({ setFieldValue, submitForm }) => {
                    return (
                      <Form>
                        <Row gutter={[0, 16]} className="py-4">
                          <Col
                            md={24}
                            className="import-icon w-100 position-relative"
                            onClick={() => {
                              setFieldValue("app", "hubspot");
                              submitForm();
                            }}
                          >
                            {user?.hubSpotRefreshToken && <div className="position-absolute badge-soft-success top-0 end-0 mt-2 me-2">Connected</div>}
                            <img src={HubspotLogo} alt="hubspot logo" width={50} />
                            <div>
                              <p>Hubspot</p>
                              <small className="text-muted">
                                Now import your contacts in Guni
                                <br />
                                Newly added or updated Hubspot contacts will be synced with Guni. Note: - Contact deletes are not synced.
                              </small>
                            </div>
                          </Col>
                          <Col
                            md={24}
                            className="import-icon w-100 position-relative"
                            onClick={() => {
                              setFieldValue("app", "zoho");
                              submitForm();
                            }}
                          >
                            {user?.zohoRefreshToken && <div className="position-absolute badge-soft-success top-0 end-0 mt-2 me-2">Connected</div>}
                            <img src={ZohoLogo} width="95" alt="zoho logo" />
                            <div>
                              <p>Zoho</p>
                              <small className="text-muted text-center">
                                Now import your contacts in Guni
                                <br />
                                Newly added or updated Zoho contacts will be synced with Guni. Note: - Contact deletes are not synced.
                              </small>
                            </div>
                          </Col>
                          <Col
                            md={24}
                            className="import-icon w-100 position-relative"
                            onClick={() => {
                              setFieldValue("app", "ac");
                              submitForm();
                            }}
                          >
                            {user?.activeCampaignToken && <div className="position-absolute badge-soft-success top-0 end-0 mt-2 me-2">Connected</div>}
                            <img src={ActiveCampLogo} width="95" alt="zoho logo" />
                            <div>
                              <p>Active Campaign</p>
                              <small className="text-muted text-center">
                                Now import your contacts in Guni
                                <br />
                                Newly added or updated Zoho contacts will be synced with Guni. Note: - Contact deletes are not synced.
                              </small>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    );
                  }}
                </Formik>
              ) : step === 2 ? (
                <GroupForm setStep={() => setStep(3)} onBack={onBack} app={formikRef.current?.values?.app} onSuccess={e => setHubspotFilter(e)} />
              ) : step === 3 ? (
                <HubspotApp setStep={setStep} onSuccess={onSuccess} filters={hubspotFilter} />
              ) : step === 4 ? (
                <ZohoApp setStep={setStep} onSuccess={onSuccess} />
              ) : (
                <ActiveCampaignApp setStep={setStep} onSuccess={onSuccess} />
              )}
            </div>
          </Tabs.TabPane>
        </Tabs>
      </Modal>

      <AlertModal
        withoutHeader
        visible={successAlert}
        title={
          <span>
            <BrandProgressiveBar className="mt-5" method={method} />
            <p className="mt-3 mb-2">Migrating Contacts</p>
          </span>
        }
        descriptionNode={
          <div className="text-center" style={{ color: "#616E88" }}>
            Contact Migration is in progress and will be added to the group shortly.
          </div>
        }
        secondaryBtn={!group}
        primaryBtnProps={{
          label: "Ok",
          onClick: () => {
            sync();
            setSuccessAlert(false);
          }
        }}
        secondaryBtnProps={{
          label: "Go to Group",
          onClick: () => {
            setSuccessAlert(false);
            history.push(`/group/${groupId}`);
          }
        }}
      />
      <AlertModal
        visible={false}
        title="Started importing contacts!"
        description="Contacts have been successfully added to the queue. Will be added to the group shortly."
        secondaryBtn={!group}
        primaryBtnProps={{
          label: "Ok",
          onClick: () => {
            sync();
            setSuccessAlert(false);
          }
        }}
        secondaryBtnProps={{
          label: "Go to Group",
          onClick: () => {
            setSuccessAlert(false);
            history.push(`/group/${groupId}`);
          }
        }}
      />
    </Fragment>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore,
  groupStore: stores.store.groupStore,
  authStore: stores.store.authStore,
  integration: stores.store.integrationStore
}))(observer(ImportContactsModal));
